/**
 * Dette er et eksempel på en modul
 * Nye moduler legges i samme folder /components/modules
 * Navngivning konvensjon av moduler: <schemaName>.tsx
 * Opprett groq/query i /lib/queries/modules/<schemaName>.ts
 * Opprett interface i /lib/types.ts
 * Legg til groq i /lib/sanity.queries.ts under const modulesQuery
 * Legg til modulen i /components/ModuleBuilder.tsx
 */

import cx from 'classnames'
import { urlForImage } from 'lib/sanity.image'
import { iHero } from 'lib/types'
import NextImage from "next/image";
import { useEffect } from 'react';
import { useInView } from "react-intersection-observer";
import { useHeaderStore } from 'store/store';

export default function Hero(module: iHero) {
  const { title, picture, subtitle, textColor } = module

  const imageUrl = picture ? urlForImage(picture).width(1920).url() : null;
  const blurdata = picture?.asset ? picture?.asset.metadata.lqip : null

  const setIsTransparent = useHeaderStore(state => state.setIsTransparent)

  const { ref, inView } = useInView({
    threshold: 0.05,
  })

  useEffect(() => {    
    setIsTransparent(inView)
  }, [inView, setIsTransparent])
  

  return (
    <section ref={ref}>
      <div
        className={cx('relative flex items-center justify-center text-center h-screen', {
          'text-white': textColor == 'white',
        })}
      >
        {imageUrl && <NextImage
          src={imageUrl}
          width={1920}
          height={1800}
          placeholder={blurdata ? 'blur' : 'empty'}
          blurDataURL={blurdata}
          quality={75}
          alt={picture?.alt || title}
          className="h-screen w-full object-cover"
        />}
        <div className="absolute max-w-[840px] px-[30px] lg:p-0">
          <div className="mb-[22px] font-cursive text-30 font-extralight lg:mb-10 lg:text-80">
            {title}
          </div>
          <div className="text-12 font-light lg:text-22">{subtitle}</div>
        </div>
      </div>
    </section>
  )
}
